import React from "react";
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ReactPlayer from 'react-player';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { findVideoById } from "../../../api/request/Details";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));
  
function createData(name, value) {
    return { name, value };
}
  
function Details(){
    const [video, setVideo] = React.useState("");
    const [rowData, setRowData] = React.useState([createData("Title", "")]);
    const {id} = useParams();
    React.useEffect(() => {
        const data = async () => {
            const result = await findVideoById(id);
            setVideo(result.data[0].video_path);
            setRowData([createData("Title", result.data[0].video_title)])
        }

        data()
    }, [])

    return(
        <>
            <Box 
                sx={{ flexGrow: 1 }}
                style={{
                    paddingRight: "1rem", 
                    paddingLeft: "1rem"
                }}>
                <Grid container spacing={2}>
                    <Grid item md={20} xs={20}>
                        <Item>
                            <ReactPlayer
                                url={`${process.env.REACT_APP_FILE_ENDPOINT}${video}`}
                                controls = {true}
                                style = {{}}
                                autoPlay={true}
                                width='100%'
                            />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Details;