import * as React from 'react';
import { Route, Routes, Navigate, Link } from "react-router-dom";
import VideoList from '../containers/video-list/VideoList';
import Details from '../containers/details/Details';
function Content() {

    const define_routes = () => {
        return(
            <Routes>
                <Route path="/" element={ <VideoList title={"News"}/> } />
                <Route path="/details/:id" element={ <Details/> } />
            </Routes>
        )
    } 

    return(
        <>
            {define_routes()}
        </>
    )
}

export default Content;