import * as React from 'react';
import TopBar from './TopBar';
import Content from "./Content";
function Index() {
    return(
        <>
            <Content />
        </>
    )
}

export default Index;