exports.log = (type='', msg='') => {

    switch(type){
        case 'success':
            console.log(`✅  ${msg}`)
            break
        case 'error':
            console.error(`⛔  ${msg}`)
            break
        case 'info':
            console.log(`📌  ${msg}`)
            break
        default:
            console.log(`❕  ${msg}`)
    }

    return true
}