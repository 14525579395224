import AxiosInstance from './Index'
import EndPoints from '../endpoints/Endpoints'
import { log } from '../../helpers/ConsoleLog'
import { SuccessResponse, ErrorResponse } from '../../helpers/Response'
export const findVideoById = async (vid) => {
    try {
        const params = new URLSearchParams()
        params.append('v_id', vid)
        const data = await AxiosInstance.post(EndPoints.find_video_by_id, params)
                    .then((data) => {
                        return SuccessResponse(data)
                    })
                    .catch((err) => {
                        log('error', `Error due to : ${err.message}`)
                        return ErrorResponse(err)
                    })
        return data
    } catch (err) {

    }
}
