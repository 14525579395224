import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Link from '@mui/material/Link';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
function ImageLoader({imageUrl}){
    const [imagePath, setImagePath] = React.useState("/assets/images/loader.gif")
    
    const handleImageLoadStart = () => {
        setImagePath("/assets/images/loader.gif")
    }

    const handleImageLoadComplete = () => {
        setTimeout(() => {
            setImagePath(imageUrl)
        }, 1000)
    }

    return (
        <>        
            <Grid item xs={6} md={2}>
                <Item>
                    <ImageListItem key={'71'} style={{minHeight: "125px"}}>
                        <img
                            src={imagePath}
                            srcSet={imagePath}
                            alt={'test title'}
                            loading="lazy"
                            onLoadStart={() => {handleImageLoadStart()}}
                            onLoad={() => {handleImageLoadComplete()}}
                        />
                        <ImageListItemBar
                            subtitle={"Very first news this one"}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.75)' }}
                                    aria-label={`info about`}
                                >
                                    <Link href="/details"  color="inherit">
                                        <PlayCircleOutlineIcon />
                                    </Link>
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                </Item>
            </Grid>
        </>
    )
}

export default ImageLoader;