import React from 'react';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ImageLoader from "../../../helpers/ImageLoader"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  padding: '1rem 0 1rem 1rem',
  color: '#fff',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};
function VideoList({title}){

    const image_list = [
        `${process.env.REACT_APP_FILE_ENDPOINT}video/streaming/thumbs/2022/cash_emi.jpg`,
        `${process.env.REACT_APP_FILE_ENDPOINT}video/streaming/thumbs/2022/kwality-mango-icecream.jpg`,
        `${process.env.REACT_APP_FILE_ENDPOINT}video/streaming/thumbs/2022/idlc.jpg`,
        `${process.env.REACT_APP_FILE_ENDPOINT}video/streaming/thumbs/2022/GP-1GB-Internet-29TK-Offer-2017.png`,
        `${process.env.REACT_APP_FILE_ENDPOINT}video/streaming/thumbs/2022/robi.jpg`,
        `${process.env.REACT_APP_FILE_ENDPOINT}video/streaming/thumbs/2022/brac-bank.jpg`,
    ]
    const [sectionTitle, setSectionTitle] = React.useState(title)    
    window.location.href = process.env.REACT_APP_CLIENT_ENDPOINT

    return(
        <>
            <ThemeProvider theme={theme}>
                <Typography variant="h3">
                </Typography>
            </ThemeProvider>
        </>
    )
}

export default VideoList